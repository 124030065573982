<template>
	<div>
		<v-row>
			<v-col lg="4" md="5" cols="12">
				<div>
					<template v-if="requestInfo != null">
						<template v-if="requestDecisionInfo && requestDecisionInfo.values.resolution.value == 1 && maxKindergartenCount">
							<v-btn block color="success" class="mb-4" @click.stop="showChooseKindergartensDialog">
								Выбрать садик
							</v-btn>
							<v-dialog v-model="chooseKindergartensDialog" width="700" :persistent="actionIsLoading">
								<v-card>
									<v-card-title class="headline lighten-2">Выбрать ...</v-card-title>
									<v-card-text>
										<v-form class="form" ref="chooseKindergartensForm">
											<div v-for="(item, index) in formKindergartenList" :key="index">
												<v-row class="mx-0">
													<v-select
														v-model="item.kindergartenId"
														:items="kindergartenList"
														required
														item-text="name"
														item-value="id"
														:rules="[v => typeof v !== 'object' || 'Required!']"
														label="Садик" class="mr-5">
														<template v-slot:item="{ item }">
															<v-list-item-content>
																<v-list-item-title>{{ item.name }} </v-list-item-title>
																<v-list-item-subtitle>{{ item.address }} </v-list-item-subtitle>
															</v-list-item-content>
														</template>
													</v-select>

													<div style="width: 200px;">
														<v-select
														v-model="item.languageId"
														:items="languageList"
														item-text="name"
														item-value="id"
														:rules="[v => typeof v !== 'object' || 'Required!']"
														label="Язык обучения">
													</v-select>
													</div>

													<v-btn :style="index ? '' : 'visibility: hidden;'" small text class="align-self-center" @click="removeRow(index)">
														<v-icon dark>mdi-close</v-icon>
													</v-btn>
												</v-row>
											</div>
										</v-form>

										<v-btn v-if="formKindergartenList.length < maxKindergartenCount" text @click="addRow" block class="mt-5">
											Добавить еще
										</v-btn>
									</v-card-text>
									<v-divider></v-divider>
									<v-card-actions>
										<v-spacer></v-spacer>
										<v-btn color="primary" text @click="chooseKindergartensSubmit" :loading="actionIsLoading">
											Сохранить
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-dialog>
						</template>
						<v-btn block color="error" class="mb-4"> <!-- TODO -->
							Снять с очереди
						</v-btn>
						<v-divider class="my-6" />
					</template>
				</div>
				<v-card elevation="2" class="mb-5" v-if="selectedKindergartenList.filter(item => !item.is_have_invitation).length">
					<v-card-title class="card-title-custom">Выбранные садики</v-card-title>
					<v-list-item v-for="(item, itemKey) in selectedKindergartenList.filter(item => !item.is_have_invitation)" :key="itemKey">
						<v-list-item-content>
							<v-list-item-title>{{ item.kindergarten_name }}</v-list-item-title>
							<v-list-item-subtitle>{{ item.lang }}</v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-icon>
							<v-btn icon @click="removeKindergarten(item, itemKey)">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-list-item-icon>
					</v-list-item>
				</v-card>
				<v-card elevation="2" class="mb-5" v-if="requestInfo">
					<v-card-title class="card-title-custom">{{requestInfo.name}}</v-card-title>
					<v-card-text class="request-data card-text-custom pt-4">
						<v-row v-for="(item, itemKey) in requestInfo.values" :key="itemKey">
							<v-col>{{item.name}}:</v-col>
							<v-col>{{item.value}}</v-col>
						</v-row>
					</v-card-text>
				</v-card>
				<v-card elevation="2" class="mb-5" v-if="requestPrivilegeInfo">
					<v-card-title class="card-title-custom">{{requestPrivilegeInfo.name}}</v-card-title>
					<v-card-text class="request-data card-text-custom pt-4">
						<v-row v-for="(item, itemKey) in requestPrivilegeInfo.values" :key="itemKey">
							<v-col>{{item.name}}:</v-col>
							<v-col v-if="item.type != 'file'">{{item.value}}</v-col>
							<v-col v-else><a :href="item.value" :download="item.fileName">{{item.fileName}}</a></v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col>
				<v-card elevation="2" v-if="selectedKindergartenList.filter(item => item.is_have_invitation).length" class="mb-5">
					<v-alert outlined  v-for="(item, itemKey) in selectedKindergartenList.filter(item => item.is_have_invitation)" :key="itemKey" color="green darken-2" class="pa-0">
						<v-card-title class="card-title-custom">Приглашение</v-card-title>
						<v-card-text class="request-data card-text-custom pt-4">
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>{{ item.kindergarten_name }}</v-list-item-title>
									<v-list-item-subtitle>{{ item.lang }}</v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-card-text>
						<v-card-actions class="justify-end">
							<v-btn text @click="acceptInvitation(item)">Принять</v-btn>
							<v-btn text @click="rejectInvitation(item)">Отклонить</v-btn>
						</v-card-actions>
					</v-alert>
				</v-card>
				<v-card elevation="2" v-if="requestDecisionInfo" class="mb-5">
					<v-alert outlined :color="(requestDecisionInfo.values.resolution.value ? 'green' : 'red')+' darken-2'" class="pa-0">
						<v-card-title class="card-title-custom">{{requestDecisionInfo.name}}</v-card-title>
						<v-card-text class="request-data card-text-custom pt-4">
							<v-row v-for="(item, itemKey) in requestDecisionInfo.values" :key="itemKey">
								<v-col>{{item.name}}:</v-col>
								<v-col>{{item.value}}</v-col>
							</v-row>
						</v-card-text>
					</v-alert>
				</v-card>
				<v-expansion-panels multiple v-model="panel" class="request-data">
					<v-expansion-panel v-for="(panel, panelKey) in requestDate" :key="panelKey">
						<v-expansion-panel-header>{{panel.name}} </v-expansion-panel-header>
						<v-expansion-panel-content>
							<div class="pt-4">
								<v-row v-for="(item, itemKey) in panel.values" :key="itemKey">
									<v-col class="prop-title">{{item.name}}</v-col>
									<v-col v-if="item.type != 'file'">{{item.value}}</v-col>
									<v-col v-else><a :href="item.value" :download="item.fileName">{{item.fileName}}</a></v-col>
								</v-row>
							</div>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-col>
		</v-row>
	</div>
</template>


<script>
	export default {
		mounted() {
			this.getData();
		},
		data: () => ({
			panel: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15], // TODO
			requestDate: {},
			requestInfo: null,
			requestId: null,
			requestPrivilegeInfo: null,
			requestDecisionInfo: null,
			chooseKindergartensDialog: false,
			actionIsLoading: false,
			kindergartenList: [],
			languageList: [],
			formKindergartenList: [],
			maxKindergartenCount: 3,
			selectedKindergartenList: [],
		}),
		methods: {
			async getData() {
				await this.$http.get('/request/'+this.$route.params.id).then((response) => {
					this.requestDate = response.data.data.block;
					this.requestInfo = response.data.data.info;
					this.requestId = this.requestInfo.values.requestId.value;
					this.requestPrivilegeInfo = response.data.data.privilege;
					if(response.data.data.decision) {
						this.requestDecisionInfo = response.data.data.decision;
					}
					if(response.data.data.preferred_kindergartens) {
						this.selectedKindergartenList = response.data.data.preferred_kindergartens.list;
						this.maxKindergartenCount = this.maxKindergartenCount - this.selectedKindergartenList.length;
					}
				}).catch(() => {});
			},
			addRow() {
				this.formKindergartenList.push({
					kindergartenId: null,
					languageId: null
				});
			},
			removeRow(index) {
				this.formKindergartenList.splice(index, 1); 
			},
			async removeKindergarten(item, index) {
				if(confirm("R U SURE?")) {
					await this.$http.post('/request/'+this.$route.params.id+'/extraordinary/delete', {
						"epId": item.id
					}).then((response) => {
						if(response.data.status == 'success') {
							this.selectedKindergartenList.splice(index, 1);
							this.maxKindergartenCount++;
						}
					}).catch(() => {});
				}
			},
			async acceptInvitation(item) {
				if(confirm("R U SURE?")) {
					await this.$http.get('/request/'+this.$route.params.id+'/extraordinary/'+item.id+'/accept').then((response) => {
						if(response.data.status == 'success') {
							this.getData();
						}
					}).catch(() => {});
				}
			},
			async rejectInvitation(item) {
				if(confirm("R U SURE?")) {
					await this.$http.get('/request/'+this.$route.params.id+'/extraordinary/'+item.id+'/reject').then((response) => {
						if(response.data.status == 'success') {
							this.getData();
						}
					}).catch(() => {});
				}
			},
			async showChooseKindergartensDialog() {
				if(!this.kindergartenList.length) {
					await this.$http.get('/request/'+this.$route.params.id+'/kindergarten').then((response) => {
						if(response.data.status == 'success') {
							this.kindergartenList = response.data.data;
						}
					}).catch(() => {});
				}
				if(!this.languageList.length) {
					await this.$http.get('/request/lang').then((response) => {
						if(response.data.status == 'success') {
							this.languageList = response.data.data;
						}
					}).catch(() => {});
				}
				this.addRow();
				this.chooseKindergartensDialog = true;
			},
			async chooseKindergartensSubmit() {
				console.log(this.formKindergartenList);

				if(this.$refs.chooseKindergartensForm.validate()) {
					this.actionIsLoading = true;
					for(let index = 0; index < this.formKindergartenList.length;) {
						await this.$http.post('/request/'+this.$route.params.id+'/extraordinary/add', {
							"kId": this.formKindergartenList[index].kindergartenId,
							"lang": this.formKindergartenList[index].languageId,
						}).then((response) => {
							if(response.data.status == 'success') {
								this.removeRow(index);
								this.maxKindergartenCount++;
							} else {
								this.$store.dispatch('alertAdd', {text: response.data.message, type: 'error'});
								index++;
							}
						}).catch(() => {
							index++;
						});
						
					}

					if(!this.formKindergartenList.length) {
						this.actionIsLoading = false;
						this.chooseKindergartensDialog = false;

						this.getData();
						this.$store.dispatch('alertAdd', {text: "success", type: 'success'});
					}

				}

			}
		},
		watch: {
			chooseKindergartensDialog: function (v) {
				if(!v) {
					this.formKindergartenList = [];
				}
			}

		}
	}
</script>

<style scoped>
	.card-title-custom {
		font-size: 15px;
		background-color: #f5f5f5;
		line-height: 1;
	}
	.card-text-custom {
		font-size: 15px;
		color: #000 !important;
	}
	.request-data .v-expansion-panel-header {
		background-color: #f5f5f5;
		min-height: initial;
		font-weight: 500;
	}
	.request-data .row + .row {
		border-top: 1px solid rgba(0, 0, 0, 0.12);
	}
	.prop-title {
		font-size: 15px;
	}
</style>